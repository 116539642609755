import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import HeaderImageSrc from '../media/images/prices_background.jpg'

// import components
import Layout from '../components/sections/layout'
import Seo from '../components/sections/seo'
import HeaderImage from '../components/headerImage'

// import templates
import BasicLayout from '../templates/basic-layout'

import BlogTeaser from '../components/blog/blogTeaser'

// styled components
import styled from 'styled-components'

const BlogList = styled.ul`
  display: flex;
  flex-flow: wrap;
  flex-direction: column;

  margin-left: -15px;
  margin-right: -15px;

  margin-top: 80px;
  margin-bottom: 60px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`

const BlogItem = styled.li`
  flex-basis: 100%;
  margin: 10px;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    flex-basis: calc(50% - 20px);
  }
`

const ModelUnderlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`

const ModelOverlay = styled.div`
  background-color: #fff;
  padding: 45px 25px 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  position: relative;
  border: rgba(31, 31, 31, 1) 2px solid;

  ul {
    margin-bottom: 50px;
  }
`

const ModelCloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  top: -25px;
  right: -25px;
  background-color: rgba(31, 31, 31, 1);

  padding: 0;
  margin: 0;

  &:after {
    content: '';
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    height: 4px;
    background: #907148;
    border-radius: 10px;
  }

  &:before {
    content: '';
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    height: 4px;
    background: #907148;
    border-radius: 10px;
  }
`

const seo_description = `Discover the affordable prices offered for Personal training sessions with Ty Paul, Personal Trainer Hove & Brighton. Level 4 Personal Trainer, 16 years experience, private studio, Brighton & Portslade.`
const seo_keywords = `PT prices, personal training, affordable prices, over 60s club, , personal training Hove, personal training Brighton, online personal training, Hove personal trainer, Best personal trainer Hove, Personal Trainer in Brighton, Personal Trainer in Hove, Personal Trainer Near Me, online personal trainer hove, online personal trainer brighton, PT Hove & Brighton, Prices`

const Prices = () => {
  const [activeModal, updateActiveModal] = useState(null)

  const updateModal = id => {
    updateActiveModal(id)
  }

  const data = useStaticQuery(query)
  const { strapiPrice, allStrapiIndividualLocation } = data
  const { title, description, SEO, hove, flansham } = strapiPrice

  const { page_description = seo_description, page_keywords = seo_keywords, page_title = title } = SEO

  const getLocationImage = loc => {
    if (allStrapiIndividualLocation.nodes.length === 0) {
      return require(`../media/images/basic_layout_placeholder_image_1.png`).default
    }
    const location = allStrapiIndividualLocation.nodes.find(item => {
      return item.title.toLowerCase() === loc
    })

    return location.main_image !== null && location.main_image.url !== '' ? location.main_image.url : require(`../media/images/basic_layout_placeholder_image_1.png`).default
  }

  const locationData = {
    hove: {
      id: 'hove',
      main_image: {
        url: getLocationImage('hove'),
      },
      title: 'Hove',
      desc: hove,
    },
    flansham: {
      id: 'flansham',
      main_image: {
        url: getLocationImage('flansham'),
      },
      title: 'Flansham',
      desc: flansham,
    },
  }

  return (
    <Layout>
      {activeModal !== null && (
        <ModelUnderlay>
          <ModelOverlay>
            <ModelCloseButton
              onClick={() => {
                updateModal(null)
              }}
            ></ModelCloseButton>
            <ReactMarkdown children={locationData?.[activeModal]?.desc || ''} />
          </ModelOverlay>
        </ModelUnderlay>
      )}
      <Seo title={page_title} description={page_description} keywords={page_keywords} />
      <HeaderImage src={HeaderImageSrc} title={title} />
      <BasicLayout pageName={title} description={description} rightImage={''} images={[]} />
      <div className="container">
        <BlogList>
          <BlogItem>
            <BlogTeaser data={locationData.hove} cb={updateModal} />
          </BlogItem>
          <BlogItem>
            <BlogTeaser data={locationData.flansham} cb={updateModal} />
          </BlogItem>
        </BlogList>
      </div>
    </Layout>
  )
}

const query = graphql`
  {
    strapiPrice {
      SEO {
        page_description
        page_keywords
        page_title
      }
      title
      description
      hove
      flansham
    }
    allStrapiIndividualLocation {
      nodes {
        title
        main_image {
          url
        }
      }
    }
  }
`

export default Prices
